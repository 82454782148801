<template>
  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('Name') }}
    </div>
    <div class="col-span-9">
      <TextboxField :type="'text'" :name="'label'" v-model="customField.label" :isRequired="true" />
    </div>
  </div>
  <div>
    <div>{{ $t('template') }}</div>
  </div>
  <div class="mt-4">
    <RichTextEditor
      v-model="customField.template"
      :editorHeight="'S'"
      :disabled="false"
      :showToolbar="false"
      :isShowCustomFields="true && !isEmptyCustomFields"
      :isShowFunctionExpressions="true"
    />
  </div>
</template>

<script lang="ts">
import TextboxField from '@/components/validateFields/TextboxField.vue'
import isEmpty from 'lodash/isEmpty'
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop } from 'vue-property-decorator'
import RichTextEditor from '../project/RichTextEditor.vue'

@Options({
  components: { TextboxField, RichTextEditor },
  name: 'Calculation',
})
export default class Calculation extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField
  @InjectReactive() customFields!: []

  get isEmptyCustomFields() {
    return isEmpty(this.customFields)
  }
}
</script>
