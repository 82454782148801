<template>
  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('Name') }}
    </div>
    <div class="col-span-9">
      <TextboxField :type="'text'" :name="'label'" v-model="customField.label" :isRequired="true" />
    </div>
  </div>
  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('customFields.countryCode') }}
    </div>
    <div class="col-span-9">
      <el-select
        :placeholder="$t('barcode_type.select')"
        v-model="customField.countryCode"
        filterable
        class="w-full"
        size="medium"
      >
        <el-option-group
          v-for="(group, groupKey) in countryList"
          :key="groupKey"
          :label="$t(`countryGroup.${groupKey}`)"
        >
          <el-option v-for="(country, key) in group" :key="key" :value="key" :label="getCountryName(country)">
            {{ getCountryName(country) }}
          </el-option>
        </el-option-group>
      </el-select>
    </div>
  </div>
</template>

<script lang="ts">
import { IProjectCustomField, ICountryCode } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'
import { POPULAR_COUNTRY_LIST } from '@/utils/constants'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'

@Options({
  components: { TextboxField },
})
export default class PhoneNumber extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField

  getCountryName(country: ICountryCode) {
    return `${this.$t(`countryList.${country.code}`)}${country.dialCode ? ` (${country.dialCode})` : ''}`
  }

  get countryList() {
    return {
      popularCountries: POPULAR_COUNTRY_LIST,
      allCountries: OTHER_COUNTRY_LIST,
    } as {
      popularCountries: Record<string, ICountryCode>
      allCountries: Record<string, ICountryCode>
    }
  }
}
</script>
