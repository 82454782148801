
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'
import { GhgEmissionCalculationType } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionCalculationType'

@Options({
  components: { TextboxField },
  name: 'Textbox',
})
export default class GhgEmission extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField

  data() {
    return {
      calculationTypeKeys: ['tonkilo', 'modifiedTonkilo', 'fuel', 'fuelEfficiency'] as GhgEmissionCalculationType[],
    }
  }

  mounted() {
    this.customField.calculationType = this.customField.calculationType
      ? this.customField.calculationType
      : this.calculationTypeKeys[0]
  }
}
