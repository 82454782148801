
import { IProjectCustomField, TOptions } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { generateNewKey } from '@/utils/helpers'
import TextboxField from '@/components/validateFields/TextboxField.vue'

@Options({
  components: { TextboxField },
  name: 'Radio',
})
export default class Radio extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField
  prefixKey = 'r'

  get getOptions(): TOptions {
    if (!this.customField.selections) {
      const newKey = generateNewKey({}, this.prefixKey)
      this.customField.selections = {
        [newKey]: { label: '', default: true },
      } as TOptions
    }

    return this.customField.selections
  }

  get isDisabledDelete() {
    return !(this.customField.selections && Object.keys(this.customField.selections).length > 1)
  }

  onChangeValidate(keyChange: string) {
    for (const key in this.customField.selections) {
      if (keyChange === key) {
        this.customField.selections[key].default = true
      } else {
        this.customField.selections[key].default = false
      }
    }
  }

  deleteOption(key: string) {
    if (this.customField.selections) {
      delete this.getOptions[key]
      Object.values(this.getOptions)[0].default = true
    }
  }

  addOption() {
    if (!this.customField.selections) {
      this.customField.selections = {} as TOptions
    }
    const newKey = generateNewKey(this.customField.selections, this.prefixKey)
    this.customField.selections[newKey] = { label: '', default: false }
  }
}
