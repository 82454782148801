<template>
  <form @submit="validateForm.onSubmit">
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="isShow"
      width="560px"
      top="0"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="isShow = false"
      @closed="$emit('dialog:close')"
    >
      <template #title>
        <el-select v-model="displayFieldType" :placeholder="$t('fieldType')" @change="onChangeComponent($event)">
          <el-option
            v-for="(componentName, key) in type2ComponentNameMapper"
            :key="key"
            :label="$t(`customFields.${key}`)"
            :value="key"
          >
            {{ $t(`customFields.${key}`) }}
          </el-option>
        </el-select>
      </template>
      <div>
        <div class="grid grid-cols-12 items-center gap-3 mb-5">
          <div class="text-right col-span-3 whitespace-nowrap">
            {{ $t('customFields.code') }}
          </div>
          <div class="col-span-9">
            <el-input
              :type="'text'"
              show-word-limit
              v-model="customFieldFormatData.customFieldKey"
              :placeholder="$t('projects.customFieldCodePlaceholder')"
              :maxlength="64"
              @blur="confirmChangeCode"
            />
          </div>
        </div>
        <component :is="getCustomFieldComponent" :customField="customFieldFormatData" />
        <div v-if="!isMandatoryBottom()" class="grid grid-cols-12 items-center gap-3 mb-5">
          <div class="text-right col-span-3"></div>
          <div class="col-span-9">
            <el-checkbox v-model="isMandatory">
              {{ $t('barcode_type.inputRequired') }}
            </el-checkbox>
          </div>
        </div>
        <div v-if="hasAllowOcrInput && isShowOcrInput" class="grid grid-cols-12 items-center gap-3 mb-5">
          <div class="text-right col-span-3">{{ $t('projects.input_support') }}</div>
          <div class="col-span-9">
            <el-checkbox @change="onChangedOCRType" v-model="hasOcrReaderType" :disabled="isDisableInputSupport">
              {{ $t('projects.ocrReaderType') }}
            </el-checkbox>
            <el-checkbox v-model="hasBarcodeReaderType" class="ml-2" :disabled="isDisableInputSupport">
              {{ $t('projects.barcodeReaderType') }}
            </el-checkbox>
          </div>
        </div>
        <div v-if="hasAllowOcrInput && isShowOcrInput" class="grid grid-cols-12 items-center gap-3 mb-5">
          <div class="text-right col-span-3"></div>
          <div class="col-span-9 custom-checkboxes-dialog">
            <div class="col-span-3 mb-3">
              {{ $t(autoOCRLabel) }}
            </div>

            <el-select
              :disabled="!hasOcrReaderType || !hasImageFileSetting || isDisableInputSupport"
              v-model="ocrImageAutoSaveField"
            >
              <el-option :label="$t('projects.none')" :value="''">
                {{ $t('projects.none') }}
              </el-option>
              <el-option v-for="item in imageFileFields" :key="item.value" :label="item.label" :value="item.value">
                {{ item.label }}
              </el-option>
            </el-select>
          </div>
        </div>
        <div v-if="hasCopyOnRecycle" class="grid grid-cols-12 items-center gap-3 mb-5">
          <div class="text-right col-span-3"></div>
          <div class="col-span-9">
            <el-checkbox v-model="copyOnRecycle" class="whitespace-pre-wrap align-middle">
              {{ $t('barcode_type.copy_on_recycle') }}
            </el-checkbox>
          </div>
        </div>
      </div>
      <div v-if="isShowHiddenField" class="grid grid-cols-12 items-center gap-3 mb-5">
        <div class="text-right col-span-3">{{ $t('barcode_type.visible') }}</div>
        <div class="col-span-9">
          <el-checkbox
            v-model="customFieldFormatData.isHidden"
            class="whitespace-pre-wrap align-middle"
            @change="changeHiddenValue()"
          >
            {{ $t('barcode_type.hide_field') }}
          </el-checkbox>
        </div>
      </div>
      <div v-if="isMandatoryBottom()" class="grid grid-cols-12 items-center gap-3 mb-5">
        <div class="text-right col-span-3"></div>
        <div class="col-span-9">
          <el-checkbox v-model="isMandatory">
            {{ $t('barcode_type.inputRequired') }}
          </el-checkbox>
        </div>
      </div>
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="isShow = false">
              {{ $t('cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" :disabled="!allowToSave" native-type="submit">
              {{ customFieldFormatData.label ? $t('save') : $t('add') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </form>
</template>

<script lang="ts">
import Calculation from '@/components/customFields/Calculation.vue'
import Calendar from '@/components/customFields/Calendar.vue'
import Checkbox from '@/components/customFields/Checkbox.vue'
import Email from '@/components/customFields/Email.vue'
import ESign from '@/components/customFields/ESign.vue'
import Files from '@/components/customFields/Files.vue'
import GhgEmission from '@/components/customFields/GhgEmission.vue'
import NumberElement from '@/components/customFields/Number.vue'
import PhoneNumber from '@/components/customFields/PhoneNumber.vue'
import Radio from '@/components/customFields/Radio.vue'
import Reference from '@/components/customFields/Reference.vue'
import Textbox from '@/components/customFields/Textbox.vue'
import i18n from '@/i18n'
import {
  ACTIVATION_CUSTOM_FIELD_EXTRA_DATA,
  LABEL_CHARACTERS_MAX,
  PROJECT_CUSTOM_FIELDS_DEFAULT,
} from '@/utils/constants'
import { runValidationPipe } from '@/utils/helpers'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import {
  ECustomFieldExtraType,
  ECustomFieldType,
  EOCRReaderType,
  EReaderType,
} from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IActivationCustomField,
  ICustomFieldFormData,
  IFieldOption,
  TOptions,
} from 'smartbarcode-web-core/src/utils/types/index'
import { useForm } from 'vee-validate'
import { Options, setup, Vue } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'

export interface IFixedValue {
  isExtraKey: boolean
  key: string
}

export type TUnionCustomField = IActivationCustomField | ICustomFieldFormData

@Options({
  components: {
    Textbox,
    Radio,
    Checkbox,
    Calendar,
    NumberElement,
    ESign,
    Files,
    PhoneNumber,
    Email,
    Reference,
    GhgEmission,
    Calculation,
  },
  emits: ['dialog:close', 'field:update', 'code:update'],
  name: 'Dialog',
})
export default class Dialog extends Vue {
  @Prop({ type: Object }) readonly customFieldData?: TUnionCustomField
  @Prop({ default: false }) readonly hasCopyOnRecycle?: boolean
  @Prop({ default: false }) readonly hasAllowOcrInput?: boolean
  @Prop({ default: true }) readonly allowToSave = true
  @Prop({ default: true }) readonly isShowHiddenField = true
  @Prop({ default: Array }) readonly imageFileFields!: IFieldOption[]
  @Prop({ default: true }) readonly isAllowedGhgEmission = true
  @Prop({ default: true }) readonly currentTrackingPointCustomFields?: Record<string, ICustomFieldFormData>

  customFieldFormatData = {} as TUnionCustomField
  oldCustomFieldKey = ''
  isShow = false
  oldFieldTypeKey = '' as string
  copyOnRecycle = false
  isShowOcrInput = false
  ocrImageAutoSaveField = ''
  isMandatory = false
  isDisableInputSupport = false

  hasOcrReaderType = false
  hasBarcodeReaderType = false

  codeEmptyErrMsg = ''
  codeIsNotValid = ''
  maxLengthErrMsg = ''
  labelMaxLengthErrMsg = ''

  get autoOCRLabel() {
    return this.isShowHiddenField
      ? 'projects.save_image_as_activation_after_ocr_manual'
      : 'projects.save_image_as_track_data_after_ocr_manual'
  }

  get isEditProject() {
    return !!this.$store.state.project.projectDetail.mainInfo?.id
  }

  get hasImageFileSetting() {
    return !isEmpty(this.imageFileFields)
  }

  mounted() {
    this.oldCustomFieldKey = this.customFieldData?.customFieldKey ?? ''
    if (!this.hasImageFileSetting) this.ocrImageAutoSaveField = ''
  }

  isMandatoryBottom() {
    return ![ECustomFieldType.TEXT, ECustomFieldExtraType.TEXT_AREA].includes(this.customFieldFormatData.fieldType)
  }

  validateForm = setup(() => {
    const { handleSubmit } = useForm()
    const onSubmit = handleSubmit((values) => {
      const newValue = this.mapUpdateData(
        { ...this.customFieldFormatData } as TUnionCustomField,
        {
          ...values,
          ocrReaderType: this.hasOcrReaderType ? EOCRReaderType.DEFAULT : EOCRReaderType.UNKNOWN,
          barcodeReaderType: this.hasBarcodeReaderType ? EReaderType.DEFAULT : EReaderType.UNKNOWN,
          ocrImageAutoSaveField: this.ocrImageAutoSaveField,
          isMandatory: this.isMandatory,
        } as TUnionCustomField
      )

      if (this.isActivationCF(newValue)) {
        newValue.copyOnRecycle = this.copyOnRecycle
      }

      if (this.validateBeforeSubmit(newValue)) {
        this.isShow = false
        this.submitFormData(newValue)
      }
    })

    return { onSubmit }
  }) as { onSubmit: Function }

  isActivationCF(field: TUnionCustomField): field is IActivationCustomField {
    return this.isShowHiddenField
  }

  onChangedOCRType(newVal: boolean) {
    if (newVal === false) this.ocrImageAutoSaveField = ''
  }

  confirmChangeCode() {
    if (this.isShow === false) return false
    if (this.isNotValidateCode(this.customFieldFormatData.customFieldKey || '')) return false

    if (
      this.isEditProject &&
      this.customFieldData?.isNew !== true &&
      this.oldCustomFieldKey !== this.customFieldFormatData.customFieldKey
    ) {
      this.$confirm(this.$t('projects.confirm_change_code'), this.$t('confirm'), {
        confirmButtonText: this.$t('ok'),
        confirmButtonClass: 'danger',
        cancelButtonText: this.$t('cancel'),
      })
        .then(() => {
          this.oldCustomFieldKey = this.customFieldFormatData?.customFieldKey ?? ''
        })
        .catch(() => (this.customFieldFormatData.customFieldKey = this.oldCustomFieldKey))
    }
  }

  isNotValidateCode(code: string) {
    const codeRegex = /^[a-zA-Z0-9_-]{3,64}$/
    if (!code) {
      openMessage(this.codeEmptyErrMsg, 'error')
      return true
    } else if (!codeRegex.test(code)) {
      openMessage(this.codeIsNotValid, 'error')
      return true
    }
    return false
  }

  validateBeforeSubmit(value: TUnionCustomField) {
    const code = value?.customFieldKey ?? ''

    const ghgCalculationTypes = this.currentTrackingPointCustomFields
      ? Object.entries(this.currentTrackingPointCustomFields)
          .filter(([key, value]) => key !== code)
          .map(([key, value]) => value)
          .filter((customField) => customField.fieldType === ECustomFieldType.GHG_EMISSION)
          .map((customField) => customField.calculationType ?? '')
      : []
    const ghgCalculationTypeSet = new Set(ghgCalculationTypes)
    return runValidationPipe([
      {
        // validate code field
        isInvalid: this.isNotValidateCode(code),
        exec: () => false,
      },
      {
        // validate field Labels
        isInvalid: value.label.length > LABEL_CHARACTERS_MAX,
        exec: () => {
          openMessage(this.labelMaxLengthErrMsg, 'error')
          return false
        },
      },
      {
        // validate checkbox, radioButton labels
        isInvalid: [ECustomFieldType.SINGLE_SELECT, ECustomFieldType.MULTI_SELECT].includes(value.fieldType),
        exec: () => {
          const options: TOptions = value.selections ?? {}
          const violatedOptions = Object.keys(options).filter((k) => options[k].label.length > LABEL_CHARACTERS_MAX)
          if (!isEmpty(violatedOptions)) {
            openMessage(this.labelMaxLengthErrMsg, 'error')
            return false
          }
          return true
        },
      },
      {
        // validate max length number of Text, TextArea
        isInvalid:
          [ECustomFieldType.TEXT, ECustomFieldExtraType.TEXT_AREA].includes(value.fieldType) && value.maxLength === 0,
        exec: () => {
          openMessage(this.maxLengthErrMsg, 'error')
          return false
        },
      },
      {
        // validate max length number of Reference
        isInvalid:
          [ECustomFieldType.REFERENCE].includes(value.fieldType) &&
          !!value.maxLength &&
          !!value.template &&
          value.maxLength < value.template.length,
        exec: () => {
          const t = i18n.global.t
          this.labelMaxLengthErrMsg = t('label_maxLength_error', { val1: value.maxLength })
          openMessage(this.labelMaxLengthErrMsg, 'error')
          return false
        },
      },
      {
        isInvalid:
          [ECustomFieldType.GHG_EMISSION].includes(value.fieldType) &&
          !!value?.calculationType &&
          // Only one ghg calc is allowed in a TP.
          ghgCalculationTypeSet.size > 0,
        exec: () => {
          const t = i18n.global.t
          openMessage(t('ghgEmission.errors.alreadyHaveGhgEmission'), 'error')
          return false
        },
      },
      {
        isInvalid:
          [ECustomFieldType.GHG_EMISSION].includes(value.fieldType) &&
          !!value?.calculationType &&
          // Only one calc type is allowed.
          // Adding another type is invalid
          // Although this case is already invalid by above one, remains for the future.
          ghgCalculationTypeSet.size === 1 &&
          !ghgCalculationTypeSet.has(value.calculationType),
        exec: () => {
          const t = i18n.global.t
          const alreadySetCalcType = ghgCalculationTypeSet.values().next().value
          openMessage(
            t('ghgEmission.errors.duplicateCalcType', { calcType: t(`ghgEmission.${alreadySetCalcType}`) }),
            'error'
          )
          return false
        },
      },
    ])
  }

  onChangeComponent(newValue: string) {
    const isExtraKey = newValue === ECustomFieldExtraType.TEXT_AREA
    const key = isExtraKey ? ECustomFieldType.TEXT : newValue

    if (this.customFieldData) {
      this.customFieldFormatData = cloneDeep(PROJECT_CUSTOM_FIELDS_DEFAULT[key])
      this.customFieldFormatData.customFieldKey = this.customFieldData.customFieldKey
      this.customFieldFormatData.order = this.customFieldData.order
      this.customFieldFormatData.label = this.customFieldData.label
      if (isExtraKey) {
        this.customFieldFormatData.multiLine = true
      }
    }
  }

  @Watch('customFieldFormatData.customFieldKey')
  onCustomFieldKeyChange() {
    this.$emit('code:update', this.customFieldFormatData.customFieldKey)
  }

  mapUpdateData(originData: TUnionCustomField, values: TUnionCustomField) {
    const removeKeys = ['ocrReaderType', 'barcodeReaderType', 'ocrImageAutoSaveField']
    for (const k in values) {
      // type Keys<T> = T extends T ? keyof T : never
      const key = k as keyof TUnionCustomField
      if (key === 'selections') {
        const value = values[key] as TOptions
        const originV = originData[key] as TOptions
        for (const subK in value) {
          const subValue = value[subK]
          const subOriginV = originV[subK]

          for (const i in subValue) {
            const index = i as 'label' | 'default'
            ;(subOriginV[index] as string | boolean) = subValue[index]
          }
        }
      } else {
        const value = typeof originData[key] === 'number' ? (Number(values[key]) as number) : (values[key] as string)
        ;(originData[key] as string | number) = value
        if (removeKeys.includes(key) && typeof value === 'string' && value.trim() === '') {
          delete originData[key]
        }
      }
    }

    return originData
  }

  created() {
    this.isShow = true
    this.setCustomFieldData()
    this.codeEmptyErrMsg = this.$t('code_empty_error')
    this.codeIsNotValid = this.$t('errors.1001', { field: this.$t('customFields.code') })
    this.maxLengthErrMsg = this.$t('maxLength_zero_error')
    this.labelMaxLengthErrMsg = this.$t('label_maxLength_error', { val1: LABEL_CHARACTERS_MAX })
  }

  fieldType = ''
  get displayFieldType() {
    return this.customFieldFormatData.fieldType === ECustomFieldType.TEXT && this.customFieldFormatData.multiLine
      ? ECustomFieldExtraType.TEXT_AREA
      : this.customFieldFormatData.fieldType
  }

  set displayFieldType(newVal: string) {
    this.fieldType = newVal
  }

  @Watch('customFieldData')
  setCustomFieldData() {
    if (!this.customFieldData) return

    this.oldFieldTypeKey = this.customFieldData.fieldType ?? ''

    // if ocrReaderType | barcodeReaderType models are ready and set
    this.hasOcrReaderType = this.customFieldData?.ocrReaderType !== EOCRReaderType.UNKNOWN
    this.hasBarcodeReaderType = this.customFieldData?.barcodeReaderType !== EReaderType.UNKNOWN

    this.isMandatory = this.customFieldData?.isMandatory ?? false
    this.ocrImageAutoSaveField = this.customFieldData.ocrImageAutoSaveField || ''

    // for activation only
    if (this.isActivationCF(this.customFieldData)) {
      const temp = this.customFieldData
      this.copyOnRecycle = temp.copyOnRecycle
      temp.isHidden = temp.isHidden ?? false
    }

    this.customFieldFormatData = cloneDeep(this.customFieldData)
    this.changeHiddenValue()
  }

  get type2ComponentNameMapper(): Record<string, string> {
    return {
      [ECustomFieldType.TEXT]: 'Textbox',
      [ECustomFieldExtraType.TEXT_AREA]: 'Textbox',
      [ECustomFieldType.NUMBER]: 'NumberElement',
      [ECustomFieldType.SINGLE_SELECT]: 'Radio',
      [ECustomFieldType.MULTI_SELECT]: 'Checkbox',
      [ECustomFieldType.DATE]: 'Calendar',
      [ECustomFieldType.ESIGN]: 'ESign',
      [ECustomFieldType.FILES]: 'Files',
      [ECustomFieldType.PHONE_NUMBER]: 'PhoneNumber',
      [ECustomFieldType.EMAIL]: 'Email',
      [ECustomFieldType.REFERENCE]: 'Reference',
      ...(this.isAllowedGhgEmission && { [ECustomFieldType.GHG_EMISSION]: 'GhgEmission' }),
      // [ECustomFieldType.CALCULATION]: 'Calculation',
    }
  }

  get getCustomFieldComponent(): string {
    const result = this.type2ComponentNameMapper[this.customFieldFormatData.fieldType]
    this.isShowOcrInput = ['Textbox', 'NumberElement', 'Reference'].includes(result)
    return result
  }

  @Emit('field:update')
  submitFormData(newValue: TUnionCustomField) {
    const defaultCustomField = Object.keys({
      ...PROJECT_CUSTOM_FIELDS_DEFAULT[newValue.fieldType],
      ...(this.isActivationCF(newValue) && ACTIVATION_CUSTOM_FIELD_EXTRA_DATA),
    })

    Object.keys(newValue).forEach((key) => {
      if (!defaultCustomField.includes(key)) {
        delete newValue[key as keyof typeof newValue]
      }
    })

    return newValue
  }

  changeHiddenValue() {
    if (this.isActivationCF(this.customFieldFormatData) && this.customFieldFormatData.isHidden) {
      this.hasOcrReaderType = false
      this.hasBarcodeReaderType = false
      this.ocrImageAutoSaveField = ''
      this.isDisableInputSupport = true
    } else {
      this.isDisableInputSupport = false
    }
  }
}
</script>
