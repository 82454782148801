<template>
  <div class="el-form-item mb-0" :class="{ 'is-error': !!fieldValidate.errorMessage }">
    <div class="el-form-item__content">
      <el-input
        :maxlength="max || null"
        show-word-limit
        v-if="!isNumber"
        :name="name"
        :id="name"
        :type="type"
        v-model="fieldValidate.inputValue"
      />
      <div class="w-32" v-else>
        <InputOnlyNumber
          :name="name"
          :id="name"
          v-model="fieldValidate.inputValue"
          :controls="true"
          :precision="0"
          :min="min"
        />
      </div>
      <p class="el-form-item__error" v-show="isShowError && fieldValidate.errorMessage">
        {{ fieldValidate.errorMessage }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'

import TextboxMixin from '@/components/mixins/TextboxMixin.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { InputOnlyNumber },
  name: 'TextboxField',
})
export default class TextboxField extends mixins(TextboxMixin) {
  @Prop({ type: Number }) readonly min?: number
  @Prop({ type: Number, default: null })
  readonly max!: number | null
}
</script>
