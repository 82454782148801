<template>
  <div
    :class="
      `grid grid-cols-5 gap-4 mb-8 relative ${
        disabled ? 'opacity-60 bg-gray-300' : 'bg-gray-50'
      } ${quillEditorHeightClass} ${isJSONFormatCorrect ? '' : 'text-red-500 border border-solid'}`
    "
  >
    <div v-if="disabled" class="quill-editor-cover-layer"></div>
    <div class="col-span-5 text-black">
      <quill-editor
        :content="editorSelectCustomData.editorModel"
        ref="quillEditor"
        theme="snow"
        :contentType="contentType"
        @selectionChange="onSelectionChange($event)"
        @update:content="onUpdateContent"
        @blur="onQuillBlur"
        :class="showToolbar ? 'ql-rich-text' : 'ql-no-rich-text'"
        :toolbar="`#quill-text-editor-toolbar-${toolbarIdRandomString}`"
        :modules="quillModules"
        :enable="!disabled"
      >
        <template #toolbar>
          <div
            :id="`quill-text-editor-toolbar-${toolbarIdRandomString}`"
            :class="`flex items-center ${!showToolbar ? 'ql-no-border' : ''}`"
          >
            <form v-if="showToolbar" :disabled="disabled">
              <select class="ql-header"></select>
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>

              <button class="ql-underline"></button>
              <button class="ql-strike"></button>
              <button class="ql-blockquote"></button>
              <button class="ql-code-block"></button>
              <select class="ql-align"></select>
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>

              <select class="ql-size"></select>
              <select class="ql-color"></select>
              <select class="ql-background"></select>
              <select class="ql-font"></select>
              <button class="ql-clean"></button>
              <button class="ql-link"></button>
              <button class="ql-image"></button>
            </form>

            <!-- custom tools -->
            <ActivationDataSyntaxSelectBox
              v-if="isShowCustomFields"
              :class="!showToolbar ? 'custom-field-select-w500' : ''"
              v-model="editorSelectCustomData.value"
              @change="onInsertCustomData($event, true)"
              :disabled="disabled"
              :excludedFieldType="excludedFieldType"
            />

            <FunctionExpressionSelectBox
              v-if="isShowFunctionExpressions"
              class="ml-2 custom-field-select-w500"
              @change="onInsertCustomData($event, false)"
              :disabled="disabled"
            />

            <div v-if="isJsonContentMode" :class="[isJsonContentMode ? 'flex justify-end w-full' : '']">
              <el-button type="primary" @click="onFormatJSONclick" size="medium">
                {{ $t('format') }}
              </el-button>
            </div>
          </div>
        </template>
      </quill-editor>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { QuillEditor, Delta } from 'sango-vue-quill'
import { Prop, PropSync, Watch, Ref } from 'vue-property-decorator'
import 'sango-vue-quill/dist/vue-quill.snow.css'
import ImageUploader from 'sango-quill-image-uploader'
import HtmlEditButton from 'sango-quill-html-edit-button'
import { uploadImage } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { ITrackPointForms, ICustomFieldFormData } from 'smartbarcode-web-core/src/utils/types/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import ActivationDataSyntaxSelectBox from '@/components/project/ActivationDataSyntaxSelectBox.vue'
import { openMessage } from '@/utils/utils'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import FunctionExpressionSelectBox from './FunctionExpressionSelectBox.vue'

export interface EditorSelectionChangeModel {
  range: {
    index: number
  }
  oldRange: {
    index: number
  }
  source: string
}

interface Op {
  insert?: string | Record<string, unknown>
  delete?: number
  retain?: number
  attributes?: { [key: string]: unknown }
}

@Options({
  components: {
    QuillEditor,
    ActivationDataSyntaxSelectBox,
    FunctionExpressionSelectBox,
  },
  emits: ['update:modelValue', 'blur', 'update:isJsonValid'],
  name: 'RichTextEditor',
})
export default class RichTextEditor extends Vue {
  @PropSync('modelValue', { type: String, default: '' }) syncedModelValue!: string
  @Prop({ default: true }) readonly disabled?: boolean
  @Prop({ default: 'L' }) readonly editorHeight?: 'L' | 'M' | 'S'
  @Prop({ default: '' }) readonly content?: string
  @Prop({ type: Boolean }) readonly showToolbar?: boolean
  @Prop({ type: Boolean }) readonly isShowCustomFields?: boolean
  @Prop({ type: Boolean }) readonly isShowFunctionExpressions?: boolean
  @Prop({ type: Boolean }) readonly isJsonContentMode?: boolean
  @Ref('quillEditor') readonly editor?: typeof QuillEditor

  isJsonFormatCorrect = true
  quillModules = this.showToolbar
    ? [
        {
          name: 'imageUploader',
          module: ImageUploader,
          options: {
            upload: (file: Blob) => {
              return new Promise((resolve, reject) => {
                const formData = new FormData()
                formData.append('file', file)
                uploadImage(formData)
                  .then((res) => resolve(res))
                  .catch((err) => {
                    reject(err)
                    errorHandler(err)
                  })
              })
            },
          },
        },
        {
          name: 'htmlEditButton',
          module: HtmlEditButton,
        },
      ]
    : []

  editorSelectCustomData = { value: '', cursorPosition: 0, editorModel: {} }
  currentDeltaValue: Delta = { ops: [{ insert: '\n\n' }] } as Delta

  get isJSONFormatCorrect() {
    if (!this.isJsonContentMode) return true
    if (this.disabled) return true
    return this.isJsonContentMode && this.isJsonFormatCorrect
  }

  get excludedFieldType() {
    if (this.isJsonContentMode) {
      return []
    }
    return [FIELD_TYPE.ESIGN, FIELD_TYPE.LOCATION, FIELD_TYPE.FILES]
  }

  onQuillBlur() {
    this.$emit('blur')
  }

  onFormatJSONclick() {
    try {
      if (!isEmpty(this.syncedModelValue.trim())) {
        this.onUpdateContent(JSON.parse(JSON.stringify(this.currentDeltaValue, null, '\t')))
        this.editor.setText(JSON.stringify(JSON.parse(this.currentDeltaValue), null, '\t'))
        this.isJsonFormatCorrect = true
      }
    } catch (error) {
      this.isJsonFormatCorrect = false
      openMessage(this.$t('json_format_error'), 'error')
    } finally {
      this.$emit('update:isJsonValid', this.isJsonFormatCorrect)
    }
  }

  get quillEditorHeightClass() {
    if (this.editorHeight === 'L') return 'ql-editor-h5'
    else if (this.editorHeight === 'M') return 'ql-editor-h2p5'
    else return 'ql-editor-h1p7'
  }

  get contentType() {
    return this.showToolbar ? 'delta' : 'text'
  }

  get toolbarIdRandomString() {
    return Math.random()
      .toString(36)
      .substr(2, 10)
  }

  flattenFields(records: Record<string, ITrackPointForms>) {
    let formDataObjs: { [idx: string]: ICustomFieldFormData } = {}
    Object.entries(records).forEach(([formIdx, form]) => {
      Object.entries(form.customFields ?? {}).forEach(([fieldKey, field]) => {
        formDataObjs = {
          ...formDataObjs,
          [`${formIdx}.${fieldKey}`]: field,
        }
      })
    })

    return formDataObjs
  }

  mounted() {
    this.onModelValueChanged()
  }

  oldSelection = { index: 0, length: 0 }
  @Watch('modelValue')
  onModelValueChanged() {
    if (this.showToolbar) {
      if (this.syncedModelValue !== this.editor.getHTML()) {
        this.editor.setHTML(this.cleanHTML(this.syncedModelValue))
      }
    } else {
      this.oldSelection = this.editor.getQuill().getSelection()
        ? this.editor.getQuill().getSelection()
        : { index: 0, length: 0 }

      this.editor.setText(this.syncedModelValue)
      this.editor.getQuill().setSelection(this.oldSelection)
    }
  }

  onSelectionChange(val: EditorSelectionChangeModel) {
    if (val.range?.index) {
      this.editorSelectCustomData.cursorPosition = val.range?.index
    }
  }

  @Watch('disabled')
  onFormDisable(val: boolean) {
    if (val) {
      this.editor.setText(this.syncedModelValue)
      this.currentDeltaValue = '{}'
      this.$emit('update:modelValue', !this.showToolbar ? '{}' : this.editor.getHTML())
    }
  }

  onUpdateContent(value: Delta | string) {
    // Check content if in json mode
    if (this.isJsonContentMode) {
      try {
        JSON.stringify(JSON.parse(value.replaceAll(/\s/g, '')), null)
        this.isJsonFormatCorrect = true
      } catch (error) {
        this.isJsonFormatCorrect = false
      } finally {
        this.$emit('update:isJsonValid', this.isJsonFormatCorrect)
      }
    }
    this.currentDeltaValue = value
    this.$emit('update:modelValue', !this.showToolbar ? value : this.editor.getHTML())

    const selection = this.editor.getQuill().selection
    if (selection.lastRange) this.editorSelectCustomData.cursorPosition = selection.savedRange.index
  }

  cleanHTML(htmlDoc: string) {
    return htmlDoc.replaceAll('\ufeff', '').replaceAll('<br>', ' ')
  }

  onInsertCustomData(data: string, isBrackets: boolean) {
    let targetPosition = this.editorSelectCustomData.cursorPosition
    const inputData = isBrackets ? `{{${data}}}` : data
    if (this.showToolbar) {
      let totalWordLength = 0

      this.currentDeltaValue.ops.forEach((e: Op) => {
        let newContent = ''
        if (typeof e.insert === 'string') {
          const isCamePosition = targetPosition < totalWordLength + e.insert.length
          if (isCamePosition) {
            Array.from(e.insert).forEach((c) => {
              newContent += totalWordLength === targetPosition ? `${inputData}${c}` : c
              totalWordLength++
            })
            e.insert = newContent
          } else {
            totalWordLength += e.insert.length
          }
        } else {
          targetPosition--
        }
      })

      this.editor.setContents(this.currentDeltaValue)
    } else {
      const content = this.editor.getText()
      const newContent = content.substr(0, targetPosition) + inputData + content.substr(targetPosition, content.length)
      const newPos = targetPosition + inputData.length
      this.editorSelectCustomData.cursorPosition = newPos
      this.editor.setText(newContent)
      this.editor.getQuill().setSelection({ index: newPos, length: 0 })
    }

    this.editorSelectCustomData.value = ''
  }
}
</script>
<style lang="scss" scoped>
.ql-editor-h1p7 :deep() {
  .ql-container {
    height: 180px;
  }

  .ql-editor {
    min-height: 170px;
    max-height: 170px;
  }
  .quill-editor-cover-layer {
    height: 230px;
  }
}

.ql-editor-h2p5 :deep() {
  .ql-container {
    height: 200px;
  }
  .ql-editor {
    min-height: 190px;
    max-height: 190px;
  }
  .quill-editor-cover-layer {
    height: 250px;
  }
}

.ql-editor-h5 :deep() {
  .ql-container {
    height: 855px;
  }
  .ql-editor {
    min-height: 795px;
    max-height: 790px;
  }
  .quill-editor-cover-layer {
    height: 895px;
  }
}

:deep() {
  .quill-editor-cover-layer {
    z-index: 1;
    position: absolute;
    width: 100%;
  }

  .custom-field-select-w500 {
    width: 510px;
  }

  .ql-no-border {
    border-style: solid;
    border-color: #d1d5db;
    border-width: 1px 1px 0px 1px;
  }

  .ql-snow {
    background: inherit;
  }

  .ql-no-rich-text {
    u {
      text-decoration: none;
    }
  }

  .ql-rich-text {
    & strong {
      font-weight: bold;
    }

    & em {
      font-style: italic;
    }

    blockquote {
      background: #f9f9f9;
      border-left: 10px solid #ccc;
      margin: 1.5em 10px;
      padding: 0.5em 10px;
      quotes: '\201C''\201D''\2018''\2019';
    }

    blockquote:before {
      color: #ccc;
      content: open-quote;
      font-size: 4em;
      line-height: 0.1em;
      margin-right: 0.25em;
      vertical-align: -0.4em;
    }
    blockquote p {
      display: inline;
    }
  }
  .el-select > .el-input {
    padding: 0px;
  }

  .el-button {
    width: auto !important;
    background-color: #525252 !important;
    border-color: #525252 !important;
    color: #ffffff !important;

    &:hover {
      background-color: rgb(0, 0, 0, 0.8) !important;
      border-color: rgb(0, 0, 0, 0.8) !important;
    }
  }
}
</style>
