<template>
  <div v-loading.fullscreen="loading" class="bg-light-gray">
    <div class="flex flex-row flex-wrap px-2 group-name-container">
      <div v-for="staff in staffList" :key="staff" class="w-1/2 py-2">
        <el-checkbox v-model="staff.isCheck" name="label">
          {{ staff.fullName }}
        </el-checkbox>
      </div>
    </div>
    <div class="w-full flex justify-center mt-6">
      <el-button
        class="justify-self-center rounded-full py-3 px-10 bg-blue-500 text-white"
        size="medium"
        @click="onSave"
      >
        {{ $t('save') }}
      </el-button>
    </div>
  </div>
</template>

<script lang="ts">
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import Dialog from '@/components/customFields/Dialog.vue'
import IconNotFound from '@/components/svg/IconNotFound.vue'
import { SAVE_PERMISSION } from '@/store/actions'
import { fetchStaff } from '@/utils/api'
import { ICreateUserGroupForm, IStaff } from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { IconNotFound, BaseInput, Dialog, ItemNotFound },
  directives: { maska },
  emits: ['update:onSave', 'update:onClose'],
  name: 'PermissionDialogSetting',
})
export default class PermissionDialogSetting extends Vue {
  @Prop({ type: Object }) readonly group!: ICreateUserGroupForm
  @Prop({ type: Object }) readonly staffs!: IStaff[]
  staffList = [] as IStaff[]
  loading = false

  async onSave() {
    try {
      const listUserChecked: string[] = []
      this.staffList.map(async (val: IStaff) => {
        if (val && val.isCheck) {
          listUserChecked.push(val.id || '')
        }
      })
      this.$store.commit(SAVE_PERMISSION, {
        [this.group.id || this.group.newGroupId || '']: listUserChecked,
      })

      this.$emit('update:onSave', {
        ...this.group,
        userIds: listUserChecked,
      })
    } catch (error) {
      openMessage(error as string, 'error')
    }
  }

  async created() {
    this.loading = true
    try {
      const staffs = this.staffs || ((await fetchStaff()) as IStaff[])
      this.staffList = cloneDeep(
        staffs.map((val) => ({
          ...val,
          isCheck: this.group.userIds?.includes(val.id || ''),
        }))
      ) as IStaff[]
    } catch (error) {
      openMessage(this.$t(`errors.${error}`), 'error')
      this.$emit('update:onClose')
    } finally {
      this.loading = false
    }
  }
}
</script>
