<template>
  <el-select :disabled="disabled" v-model="syncedModelValue" :placeholder="$t('field_select')">
    <el-option v-for="(option, key) in customFieldOptions" :key="key" :value="option.value" :label="option.label">
      {{ option.label }}
    </el-option>
  </el-select>
</template>
<script lang="ts">
import { getCustomFieldOptions, getStaticOptions } from '@/utils/helpers'
import { ICustomFieldFormData } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'
interface IFieldOption {
  label: string
  value: string
}

@Options({
  components: {},
  emits: ['update:modelValue'],
  name: 'ActivationDataSyntaxSelectBox',
})
export default class ActivationDataSyntaxSelectBox extends Vue {
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @PropSync('modelValue', { type: String, default: '' }) readonly syncedModelValue!: string
  @Prop({ default: true }) readonly disabled?: boolean
  @Prop({ default: [] }) readonly excludedFieldType: string[] = []

  get customFieldOptions(): IFieldOption[] {
    const options = getStaticOptions()
    const customField = getCustomFieldOptions({ fields: this.customFields, excludeFieldType: this.excludedFieldType })
    options.push(...customField)
    return options
  }
}
</script>
