
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import Dialog from '@/components/customFields/Dialog.vue'
import IconNotFound from '@/components/svg/IconNotFound.vue'
import IconVertialThreeDotCircle from '@/components/svg/IconVertialThreeDotCircle.vue'
import { maska } from 'maska'
import moment from 'moment'
import { ICreateUserGroup, ICreateUserGroupForm, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  components: { IconNotFound, BaseInput, Dialog, ItemNotFound, IconVertialThreeDotCircle },
  directives: { maska },
  emits: ['update:tablePermission', 'update:onGroupClicked', 'showDialog:changeName'],
  name: 'PermissionTable',
})
export default class PermissionTable extends Vue {
  @Prop({ type: String }) readonly projectId!: string
  @PropSync('permissionGroup', { type: Object }) _groupPermission?: ICreateUserGroupForm[]
  @Prop({ type: Object }) readonly trackPoints!: ITrackpoint
  @Prop({ type: Array }) readonly trackPointsHeaderName!: string[]

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  onSettingClicked(index: number) {
    this.$emit('update:onGroupClicked', index)
  }

  created() {
    this.initData()
  }

  @Watch('_groupPermission')
  initData() {
    if (this._groupPermission) {
      this._groupPermission.sort((a, b) => {
        return moment(a?.audit?.createdDateTime || '').isAfter(moment(b?.audit?.createdDateTime)) ? -1 : 1
      })
    }
  }

  onOpenEditNameModal(index: number) {
    this.$emit('showDialog:changeName', index)
  }

  onDelete(index: number) {
    this.$confirm(this.$t('projects.confirm_remove_user_group'), this.$t('confirm'), {
      confirmButtonText: this.$t('ok'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(async () => {
        if (this._groupPermission) {
          this._groupPermission.splice(index, 1)
        }
      })
      .catch(() => true)
  }

  convertTrackPoint(trackpoint: ICreateUserGroup) {
    if (trackpoint?.projectPermissions[this.projectId]) {
      return trackpoint?.projectPermissions[this.projectId]?.trackingPermissions || {}
    } else {
      return Object.values(trackpoint?.projectPermissions)[0]?.trackingPermissions || {}
    }
  }

  convertCanViewActivation(trackpoint: ICreateUserGroup) {
    let permission = {}
    if (trackpoint?.projectPermissions[this.projectId]) {
      permission = trackpoint?.projectPermissions[this.projectId]?.activationDataPermission || {}
    } else {
      permission = Object.values(trackpoint?.projectPermissions)[0]?.activationDataPermission || {}
    }
    return { 0: permission }
  }

  convertCanEditGenerateBarcodePermission(trackpoint: ICreateUserGroup) {
    let permission = {}
    if (trackpoint?.projectPermissions[this.projectId]) {
      permission = trackpoint?.projectPermissions[this.projectId]?.generateBarcodePermission || {}
    } else {
      permission = Object.values(trackpoint?.projectPermissions)[0]?.generateBarcodePermission || {}
    }
    return { 0: permission }
  }

  updated() {
    this.$emit('update:tablePermission', this._groupPermission)
  }
}
