<template>
  <el-select :disabled="disabled" v-model="syncedModelValue" :placeholder="$t('field_select')">
    <el-option v-for="(option, key) in options" :key="key" :value="option.value" :label="option.label">
      {{ option.label }}
    </el-option>
  </el-select>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

interface IFieldOption {
  label: string
  value: string
}

@Options({
  components: {},
  emits: ['update:modelValue'],
  name: 'ActivationDataSyntaxSelectBox',
})
export default class FunctionExpressionSelectBox extends Vue {
  @PropSync('modelValue', { type: String, default: '' }) readonly syncedModelValue!: string
  @Prop({ default: true }) readonly disabled?: boolean

  get options(): IFieldOption[] {
    return [
      { label: 'SUM', value: 'sum()' },
      { label: 'AVERAGE', value: 'average()' },
      { label: 'POWER', value: 'power()' },
      { label: 'ADDDATE', value: 'addDate()' },
    ]
  }
}
</script>
