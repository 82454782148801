
import { generateNewKey } from '@/utils/helpers'
import { IProjectCustomField, TOptions } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'

@Options({
  components: { TextboxField },
  name: 'Checkbox',
})
export default class Checkbox extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField

  prefixKey = 'c'

  get getOptions(): TOptions {
    if (!this.customField.selections) {
      const newKey = generateNewKey({}, this.prefixKey)
      this.customField.selections = {
        [newKey]: { label: '', default: false },
      } as TOptions
    }

    return this.customField.selections
  }

  get isDisabledDelete() {
    return !(this.customField.selections && Object.keys(this.customField.selections).length > 1)
  }

  deleteOption(key: string) {
    if (this.customField.selections) {
      delete this.customField.selections[key]
    }
  }

  addOption() {
    if (!this.customField.selections) {
      this.customField.selections = {} as TOptions
    }
    const newKey = generateNewKey(this.customField.selections, this.prefixKey)
    this.customField.selections[newKey] = { label: '', default: false }
  }
}
