<template>
  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('Name') }}
    </div>
    <div class="col-span-9">
      <TextboxField :type="'text'" :name="'label'" v-model="customField.label" :isRequired="true" />
    </div>
  </div>

  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('customFields.min') }}
    </div>
    <div class="col-span-9">
      <div class="w-44">
        <InputOnlyNumber
          :min="0"
          :max="customField.maxValue"
          :name="'minValue'"
          v-model="customField.minValue"
          :controls="true"
        />
      </div>
    </div>
  </div>

  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('customFields.max') }}
    </div>
    <div class="col-span-9">
      <div class="w-44">
        <InputOnlyNumber
          :min="customField.minValue"
          :name="'maxValue'"
          v-model="customField.maxValue"
          :controls="true"
        />
      </div>
    </div>
  </div>

  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('customFields.precision') }}
    </div>
    <div class="col-span-9">
      <TextboxField :type="'text'" :name="'precision'" v-model="customField.precision" :isNumber="true" :min="0" />
    </div>
  </div>
</template>

<script lang="ts">
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'

@Options({
  components: { TextboxField, InputOnlyNumber },
  name: 'Number',
})
export default class Number extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField
}
</script>
