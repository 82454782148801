<template>
  <el-form :disabled="isReadOnlyMode">
    <div class="mt-2">
      <div v-if="_groupPermission.length > 0 && Object.keys(trackPoints).length > 0" class="overflow-x-auto">
        <table class="w-full rounded text-center">
          <thead class="bg-gray-200 word-break">
            <tr class="bg-gray-custom">
              <th class="border-solid border border-gray-300 px-5 py-4 text-center" colspan="2">
                {{ $t('permissions.group') }}
              </th>
              <th class="border-solid border border-gray-300 px-5 py-4 text-center align-middle w-5">
                {{ $t('barcode_type.generate_barcode') }}
              </th>
              <th class="border-solid border border-gray-300 px-5 py-4 text-center align-middle w-5">
                {{ $t('barcode_type.basic_info') }}
              </th>
              <th
                class="border-solid border border-gray-300 px-5 py-4 text-center align-middle"
                :key="trackPoint"
                v-for="trackPoint in trackPointsHeaderName"
              >
                {{ trackPoint }}
              </th>
              <th class="border-solid border border-gray-300 px-5 py-4 text-center align-middle">
                {{ $t('permissions.setting') }}
              </th>
            </tr>
          </thead>
          <tbody class="permission-tab overflow-auto">
            <template v-for="(user, index) in _groupPermission" :key="user">
              <tr :class="index % 2 === 0 ? 'bg-white' : 'blue-background-access-group'">
                <td
                  class="px-2 py-3 border-solid border-t-1 border-b-1 border-gray-300 whitespace-nowrap align-middle"
                  :rowspan="3"
                >
                  <div class="flex flex-col items-center text-center">
                    {{ user.name }}
                    <el-popover placement="bottom-end" :width="200" trigger="hover" popper-class="popover-barcode-list">
                      <template #reference>
                        <div class="w-5 h-5 mt-4 cover-svg-icon">
                          <IconVertialThreeDotCircle />
                        </div>
                      </template>
                      <div @click="onOpenEditNameModal(index)" :class="['cursor-pointer text-center', 'text-black']">
                        {{ $t('projects.changeName') }}
                      </div>
                      <div class="border-solid border-b-1 border-gray-300 my-2"></div>
                      <div @click="onDelete(index)" class="cursor-pointer text-center text-red-500">
                        {{ $t('delete') }}
                      </div>
                    </el-popover>
                  </div>
                </td>
                <td class="px-2 py-3 border-solid border-t-1 border-gray-300 text-center whitespace-nowrap">
                  {{ $t('permissions.writeable') }}
                </td>
                <td
                  class="px-2 py-3 border-solid border border-gray-300 text-center"
                  :key="generateBarcodePermission"
                  v-for="generateBarcodePermission in convertCanEditGenerateBarcodePermission(user)"
                >
                  <el-checkbox v-model="generateBarcodePermission.canEdit" />
                </td>
                <td
                  class="px-2 py-3 border-solid border border-gray-300 text-center"
                  :key="activationDataPermission"
                  v-for="activationDataPermission in convertCanViewActivation(user)"
                >
                  <el-checkbox v-model="activationDataPermission.canEdit" />
                </td>
                <td
                  class="px-2 py-3 border-solid border border-gray-300 text-center"
                  :key="trackPointEdit"
                  v-for="trackPointEdit in convertTrackPoint(user)"
                >
                  <el-checkbox v-model="trackPointEdit.canEdit" />
                </td>
                <td
                  class="px-2 py-3 border-solid border-t-1 border-b-1 border-gray-300 text-center align-middle"
                  :rowspan="3"
                >
                  <el-button style="padding: 14px" type="primary" @click="onSettingClicked(index)">
                    {{ $t('setting') }}
                  </el-button>
                </td>
              </tr>
              <tr :class="index % 2 === 0 ? 'bg-white' : 'blue-background-access-group'">
                <td class="px-2 py-3 border-solid border-b-1 border-gray-300 text-center whitespace-nowrap">
                  {{ $t('permissions.skippable') }}
                </td>
                <td class="px-2 py-3 border-solid border-l border-r border-gray-300 text-center"></td>
                <td class="px-2 py-3 border-solid border-l border-r border-gray-300 text-center"></td>
                <td
                  class="px-2 py-3 border-solid border border-gray-300 text-center"
                  :key="trackPointEdit"
                  v-for="trackPointEdit in convertTrackPoint(user)"
                >
                  <el-checkbox v-model="trackPointEdit.canSkip" :disabled="!trackPointEdit.canEdit" />
                </td>
              </tr>

              <tr :class="index % 2 === 0 ? 'bg-white' : 'blue-background-access-group'">
                <td class="px-2 py-3 border-solid border-t-1 border-b-1 border-gray-300 text-center whitespace-nowrap">
                  {{ $t('permissions.readable') }}
                </td>
                <td class="px-2 py-3 border-solid border-l border-r border-gray-300 text-center"></td>
                <td
                  class="px-2 py-3 border-solid border border-gray-300 text-center"
                  :key="activationDataPermission"
                  v-for="activationDataPermission in convertCanViewActivation(user)"
                >
                  <el-checkbox v-model="activationDataPermission.canView" />
                </td>
                <td
                  class="px-2 py-3 border-solid border border-gray-300 text-center"
                  :key="trackPointView"
                  v-for="trackPointView in convertTrackPoint(user)"
                >
                  <el-checkbox v-model="trackPointView.canView" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <ItemNotFound v-else :content="$t('permissions.no_data')" />
    </div>
  </el-form>
</template>

<script lang="ts">
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import Dialog from '@/components/customFields/Dialog.vue'
import IconNotFound from '@/components/svg/IconNotFound.vue'
import IconVertialThreeDotCircle from '@/components/svg/IconVertialThreeDotCircle.vue'
import { maska } from 'maska'
import moment from 'moment'
import { ICreateUserGroup, ICreateUserGroupForm, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  components: { IconNotFound, BaseInput, Dialog, ItemNotFound, IconVertialThreeDotCircle },
  directives: { maska },
  emits: ['update:tablePermission', 'update:onGroupClicked', 'showDialog:changeName'],
  name: 'PermissionTable',
})
export default class PermissionTable extends Vue {
  @Prop({ type: String }) readonly projectId!: string
  @PropSync('permissionGroup', { type: Object }) _groupPermission?: ICreateUserGroupForm[]
  @Prop({ type: Object }) readonly trackPoints!: ITrackpoint
  @Prop({ type: Array }) readonly trackPointsHeaderName!: string[]

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  onSettingClicked(index: number) {
    this.$emit('update:onGroupClicked', index)
  }

  created() {
    this.initData()
  }

  @Watch('_groupPermission')
  initData() {
    if (this._groupPermission) {
      this._groupPermission.sort((a, b) => {
        return moment(a?.audit?.createdDateTime || '').isAfter(moment(b?.audit?.createdDateTime)) ? -1 : 1
      })
    }
  }

  onOpenEditNameModal(index: number) {
    this.$emit('showDialog:changeName', index)
  }

  onDelete(index: number) {
    this.$confirm(this.$t('projects.confirm_remove_user_group'), this.$t('confirm'), {
      confirmButtonText: this.$t('ok'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(async () => {
        if (this._groupPermission) {
          this._groupPermission.splice(index, 1)
        }
      })
      .catch(() => true)
  }

  convertTrackPoint(trackpoint: ICreateUserGroup) {
    if (trackpoint?.projectPermissions[this.projectId]) {
      return trackpoint?.projectPermissions[this.projectId]?.trackingPermissions || {}
    } else {
      return Object.values(trackpoint?.projectPermissions)[0]?.trackingPermissions || {}
    }
  }

  convertCanViewActivation(trackpoint: ICreateUserGroup) {
    let permission = {}
    if (trackpoint?.projectPermissions[this.projectId]) {
      permission = trackpoint?.projectPermissions[this.projectId]?.activationDataPermission || {}
    } else {
      permission = Object.values(trackpoint?.projectPermissions)[0]?.activationDataPermission || {}
    }
    return { 0: permission }
  }

  convertCanEditGenerateBarcodePermission(trackpoint: ICreateUserGroup) {
    let permission = {}
    if (trackpoint?.projectPermissions[this.projectId]) {
      permission = trackpoint?.projectPermissions[this.projectId]?.generateBarcodePermission || {}
    } else {
      permission = Object.values(trackpoint?.projectPermissions)[0]?.generateBarcodePermission || {}
    }
    return { 0: permission }
  }

  updated() {
    this.$emit('update:tablePermission', this._groupPermission)
  }
}
</script>
<style lang="scss" scoped>
.word-break {
  word-break: keep-all;
}
.permission-tab {
  max-height: calc(100vh - 180px - 60px);
}
.blue-background-access-group {
  background: #ecf7fb;
}
</style>
