
import i18n from '@/i18n'
import { useField } from 'vee-validate'
import { Options, setup, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import * as yup from 'yup'
const t = i18n.global.t
@Options({
  emits: ['update:modelValue'],
  name: 'TextboxMixin',
})
export default class TextboxMixin extends Vue {
  @Prop({ type: String }) readonly name?: string
  @Prop({ default: 'text' }) readonly type?: string
  @Prop({ type: [String, Number] }) readonly modelValue?: string | number
  @Prop({ type: Boolean }) readonly isNumber?: boolean
  @Prop({ type: Boolean }) readonly isRequired?: boolean
  @Prop({ default: true }) readonly isShowError!: boolean
  @Prop({ default: false }) readonly isValidateOnMount!: boolean

  transform(value: string, originalValue: string) {
    const trimmedValue = String(originalValue).trim()
    return trimmedValue !== '' && trimmedValue !== '*' ? value : null
  }

  fieldValidate = setup(() => {
    let yupValid
    yupValid = this.isNumber ? yup.number().typeError('This field is number.') : yup.string()

    if (this.isRequired) {
      yupValid = yupValid.required(t('this_field_is_required'))
    } else {
      if (this.isNumber) {
        yupValid = yupValid.nullable().transform(this.transform)
      }
    }

    const { value: inputValue, errorMessage, handleChange } = useField(this.name, yupValid, {
      initialValue: this.modelValue,
      validateOnMount: this.isValidateOnMount,
      bails: true,
    })

    return {
      handleChange,
      errorMessage,
      inputValue,
    }
  })

  @Watch('fieldValidate.inputValue')
  onModelChanged(newVal: string) {
    this.$emit('update:modelValue', newVal)
  }

  handleInput(event: Vue) {
    this.$emit('update:modelValue', event?.target?.value)
  }
}
