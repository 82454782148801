<template>
  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('Name') }}
    </div>
    <div class="col-span-9">
      <TextboxField :type="'text'" :name="'label'" v-model="customField.label" :isRequired="true" />
    </div>
  </div>
  <div class="grid grid-cols-12 items-start gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('customFields.type') }}
    </div>
    <div class="col-span-9">
      <el-radio-group v-model="customField.fileKind" class="ml-2">
        <el-radio key="image" :value="fileOptions.IMAGE" :label="fileOptions.IMAGE">
          {{ $t('customFields.image') }}
        </el-radio>
        <el-radio key="unknown" :value="fileOptions.UNKNOWN" :label="fileOptions.UNKNOWN">
          {{ $t('customFields.unknown') }}
        </el-radio>
        <el-radio
          :key="fileOptions.BARCODE_SCAN_FRAME"
          :value="fileOptions.BARCODE_SCAN_FRAME"
          :label="fileOptions.BARCODE_SCAN_FRAME"
          class="flex justify-start pre-line py-2"
        >
          {{ $t(`customFields.${fileOptions.BARCODE_SCAN_FRAME}`) }}
        </el-radio>
        <el-radio :key="fileOptions.PHOTO_ONLY" :value="fileOptions.PHOTO_ONLY" :label="fileOptions.PHOTO_ONLY">
          {{ $t(`customFields.${fileOptions.PHOTO_ONLY}`) }}
        </el-radio>
      </el-radio-group>
    </div>
  </div>
  <!-- image file for overlay image -->
  <div v-if="customField.fileKind === fileOptions.PHOTO_ONLY" class="grid grid-cols-12 items-start gap-3 mb-5">
    <div class="text-right col-span-3"></div>
    <div class="col-span-9">
      <el-button type="primary" icon="el-icon-plus" size="medium" @click="onUploaderClick">
        {{ $t('customFields.guideFrameImage') }}
      </el-button>
      <el-upload
        ref="inputUpload"
        class="overlay-photo-uploader mt-4"
        action="#"
        :auto-upload="false"
        :multiple="false"
        :file-list="fileList"
        :on-change="onFilesChanged"
        list-type="picture-card"
        :limit="1"
        :with-credentials="true"
        :http-request="httpRequestController"
        :on-success="onSuccess"
        :on-remove="onRemove"
        accept="image/*"
      >
        <template #default>
          <el-button ref="uploadBtn" v-show="false" />
        </template>
      </el-upload>
    </div>
  </div>
  <canvas id="temp-canvas2" style="display: none" />
  <div class="guide-frame-image-dialog">
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="isShowGuideFrameImage"
      width="700px"
      top="0"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="closeCropperDialog"
    >
      <template #title>
        <div>{{ $t('customFields.guideFrameImage') }}</div>
      </template>

      <cropper class="cropper" :src="imageUrl" @change="onCropperChanged" />
      <canvas id="temp-canvas" style="display: none" />

      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="closeCropperDialog">
              {{ $t('cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="addCroppedImage">
              {{ $t('add') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'
import IconImage from '@/components/svg/custom-field/IconImage.vue'
import { EFileKind } from 'smartbarcode-web-core/src/utils/enums/index'
import { UploadFile, ElUploadRequestOptions } from 'element-plus/lib/el-upload/src/upload.type'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { AxiosRequestConfig } from 'axios'
import { customRequest, removeImage } from '@/utils/api'
import isEmpty from 'lodash/isEmpty'

export interface ICropperData {
  coordinates: {
    left: number
    top: number
    width: number
    height: number
  }
  canvas: HTMLCanvasElement
}

@Options({
  components: { TextboxField, IconImage, Cropper },
  name: 'Files',
})
export default class Files extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField

  isShowGuideFrameImage = false

  imageUrl = ''

  isUploader = true

  fileList: UploadFile[] = []
  tempFile: UploadFile[] = []
  cropperData: undefined | ICropperData = undefined

  async onRemove(uploadFile: UploadFile, uploadFiles: UploadFile[]) {
    const fileUrl = uploadFile?.response as string
    if (isEmpty(fileUrl)) return
    await removeImage(fileUrl)
  }

  closeCropperDialog(isHaveNewFile: boolean) {
    this.isShowGuideFrameImage = false

    if (isHaveNewFile) {
      this.tempFile = []
    }
  }

  async onSuccess(response: string) {
    this.customField.overlayImage = response
  }

  onUploaderClick() {
    this.tempFile.push(this.fileList[0])
    this.fileList = []
    this.$refs.uploadBtn.$el.click()
  }

  onCropperChanged(cropperData: ICropperData) {
    if (!cropperData) return
    this.cropperData = cropperData
  }

  replaceCurrentFile(newBlob: Blob | null) {
    if (!newBlob) return

    const uploadFile = new File([newBlob], `${this.fileList[0].name}.png`, { type: 'image/png' })
    // this.fileList[0].url = (window.URL || window.webkitURL).createObjectURL(uploadFile)

    this.$refs.inputUpload.clearFiles() // handleRemove(this.fileList[0])
    this.$refs.inputUpload.handleStart(uploadFile)
    this.$refs.inputUpload.submit()
  }

  addCroppedImage() {
    if (!this.cropperData) return
    const { coordinates, canvas } = { ...this.cropperData }
    const ctx = this.cropperData.canvas.getContext('2d') as CanvasRenderingContext2D
    const imgData = ctx.getImageData(coordinates.left, coordinates.top, coordinates.width, coordinates.height)
    const tempCanvas = document.getElementById('temp-canvas') as HTMLCanvasElement
    const tempCtx = tempCanvas.getContext('2d') as CanvasRenderingContext2D

    tempCanvas.width = canvas.width
    tempCanvas.height = canvas.height
    tempCtx.putImageData(imgData, 0, 0)

    canvas.toBlob((blob) => {
      this.replaceCurrentFile(blob)
      this.closeCropperDialog(true)
    }, 'image/png')
  }

  @Watch('customField.fileKind')
  onFileKindChanged(val: string) {
    if (val !== this.fileOptions.PHOTO_ONLY) {
      this.imageUrl = ''
    }
  }

  onFilesChanged(file: UploadFile, files: UploadFile[]) {
    if (file.status !== 'ready') return
    this.imageUrl = file.url ?? ''
    this.fileList = files
    this.isShowGuideFrameImage = true
  }

  get fileOptions() {
    return EFileKind
  }

  httpRequestController(options: ElUploadRequestOptions) {
    const formData = new FormData()
    formData.append('file', options.file)
    const request = {
      url: 'user/enterprise/file',
      method: 'post',
      headers: options.headers as unknown,

      data: formData,
      onUploadProgress: (progressEvent) => {
        progressEvent.percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        options.onProgress(progressEvent)
      },
      withCredentials: true,
      timeout: 1800000, // 30'
    } as AxiosRequestConfig

    return customRequest(request)
  }

  mounted() {
    if (this.customField.overlayImage) {
      const url = this.customField.overlayImage
      this.fileList.push({ name: 'overlay-image.png', url: url, response: url } as UploadFile)
    }
  }
}
</script>
<style lang="scss" scoped>
.guide-frame-image-dialog :deep() {
  .el-overlay .el-dialog {
    max-width: 700px;
  }
}

:deep() {
  .overlay-photo-uploader {
    .el-upload--picture-card {
      display: none;
    }
  }

  .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    object-fit: contain;
  }

  .el-upload-list__item-preview {
    display: none !important;
  }

  .el-upload-list__item-delete {
    padding-right: 8px;
  }

  .el-icon-close-tip {
    display: none !important;
  }

  .vue-advanced-cropper__background,
  .vue-advanced-cropper__foreground {
    background-color: white;
  }
  .vue-rectangle-stencil {
    border: 2px solid black;
  }
  .vue-simple-handler {
    background-color: white;
    width: 7px;
    height: 7px;
  }
  .vue-handler-wrapper {
    background-color: black;
    width: 15px;
    height: 15px;
  }
}
.overlay-photo-uploader .overlay-photo-thumbnail {
  width: 145px;
  height: 145px;
  display: block;
}

.overlay-photo-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.overlay-photo-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.overlay-photo-uploader-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #8c939d;
  width: 143px;
  height: 143px;
  text-align: center;
  border: 3px dashed #8c939d;
  border-radius: 10px;
}
.el-upload-list {
  width: 150px;
}
</style>
