
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'
import IconImage from '@/components/svg/custom-field/IconImage.vue'
import { EFileKind } from 'smartbarcode-web-core/src/utils/enums/index'
import { UploadFile, ElUploadRequestOptions } from 'element-plus/lib/el-upload/src/upload.type'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { AxiosRequestConfig } from 'axios'
import { customRequest, removeImage } from '@/utils/api'
import isEmpty from 'lodash/isEmpty'

export interface ICropperData {
  coordinates: {
    left: number
    top: number
    width: number
    height: number
  }
  canvas: HTMLCanvasElement
}

@Options({
  components: { TextboxField, IconImage, Cropper },
  name: 'Files',
})
export default class Files extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField

  isShowGuideFrameImage = false

  imageUrl = ''

  isUploader = true

  fileList: UploadFile[] = []
  tempFile: UploadFile[] = []
  cropperData: undefined | ICropperData = undefined

  async onRemove(uploadFile: UploadFile, uploadFiles: UploadFile[]) {
    const fileUrl = uploadFile?.response as string
    if (isEmpty(fileUrl)) return
    await removeImage(fileUrl)
  }

  closeCropperDialog(isHaveNewFile: boolean) {
    this.isShowGuideFrameImage = false

    if (isHaveNewFile) {
      this.tempFile = []
    }
  }

  async onSuccess(response: string) {
    this.customField.overlayImage = response
  }

  onUploaderClick() {
    this.tempFile.push(this.fileList[0])
    this.fileList = []
    this.$refs.uploadBtn.$el.click()
  }

  onCropperChanged(cropperData: ICropperData) {
    if (!cropperData) return
    this.cropperData = cropperData
  }

  replaceCurrentFile(newBlob: Blob | null) {
    if (!newBlob) return

    const uploadFile = new File([newBlob], `${this.fileList[0].name}.png`, { type: 'image/png' })
    // this.fileList[0].url = (window.URL || window.webkitURL).createObjectURL(uploadFile)

    this.$refs.inputUpload.clearFiles() // handleRemove(this.fileList[0])
    this.$refs.inputUpload.handleStart(uploadFile)
    this.$refs.inputUpload.submit()
  }

  addCroppedImage() {
    if (!this.cropperData) return
    const { coordinates, canvas } = { ...this.cropperData }
    const ctx = this.cropperData.canvas.getContext('2d') as CanvasRenderingContext2D
    const imgData = ctx.getImageData(coordinates.left, coordinates.top, coordinates.width, coordinates.height)
    const tempCanvas = document.getElementById('temp-canvas') as HTMLCanvasElement
    const tempCtx = tempCanvas.getContext('2d') as CanvasRenderingContext2D

    tempCanvas.width = canvas.width
    tempCanvas.height = canvas.height
    tempCtx.putImageData(imgData, 0, 0)

    canvas.toBlob((blob) => {
      this.replaceCurrentFile(blob)
      this.closeCropperDialog(true)
    }, 'image/png')
  }

  @Watch('customField.fileKind')
  onFileKindChanged(val: string) {
    if (val !== this.fileOptions.PHOTO_ONLY) {
      this.imageUrl = ''
    }
  }

  onFilesChanged(file: UploadFile, files: UploadFile[]) {
    if (file.status !== 'ready') return
    this.imageUrl = file.url ?? ''
    this.fileList = files
    this.isShowGuideFrameImage = true
  }

  get fileOptions() {
    return EFileKind
  }

  httpRequestController(options: ElUploadRequestOptions) {
    const formData = new FormData()
    formData.append('file', options.file)
    const request = {
      url: 'user/enterprise/file',
      method: 'post',
      headers: options.headers as unknown,

      data: formData,
      onUploadProgress: (progressEvent) => {
        progressEvent.percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        options.onProgress(progressEvent)
      },
      withCredentials: true,
      timeout: 1800000, // 30'
    } as AxiosRequestConfig

    return customRequest(request)
  }

  mounted() {
    if (this.customField.overlayImage) {
      const url = this.customField.overlayImage
      this.fileList.push({ name: 'overlay-image.png', url: url, response: url } as UploadFile)
    }
  }
}
