
import { Options, mixins } from 'vue-class-component'

import TextboxMixin from '@/components/mixins/TextboxMixin.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { InputOnlyNumber },
  name: 'TextboxField',
})
export default class TextboxField extends mixins(TextboxMixin) {
  @Prop({ type: Number }) readonly min?: number
  @Prop({ type: Number, default: null })
  readonly max!: number | null
}
