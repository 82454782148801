
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import Dialog from '@/components/customFields/Dialog.vue'
import IconNotFound from '@/components/svg/IconNotFound.vue'
import { SAVE_PERMISSION } from '@/store/actions'
import { fetchStaff } from '@/utils/api'
import { ICreateUserGroupForm, IStaff } from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { IconNotFound, BaseInput, Dialog, ItemNotFound },
  directives: { maska },
  emits: ['update:onSave', 'update:onClose'],
  name: 'PermissionDialogSetting',
})
export default class PermissionDialogSetting extends Vue {
  @Prop({ type: Object }) readonly group!: ICreateUserGroupForm
  @Prop({ type: Object }) readonly staffs!: IStaff[]
  staffList = [] as IStaff[]
  loading = false

  async onSave() {
    try {
      const listUserChecked: string[] = []
      this.staffList.map(async (val: IStaff) => {
        if (val && val.isCheck) {
          listUserChecked.push(val.id || '')
        }
      })
      this.$store.commit(SAVE_PERMISSION, {
        [this.group.id || this.group.newGroupId || '']: listUserChecked,
      })

      this.$emit('update:onSave', {
        ...this.group,
        userIds: listUserChecked,
      })
    } catch (error) {
      openMessage(error as string, 'error')
    }
  }

  async created() {
    this.loading = true
    try {
      const staffs = this.staffs || ((await fetchStaff()) as IStaff[])
      this.staffList = cloneDeep(
        staffs.map((val) => ({
          ...val,
          isCheck: this.group.userIds?.includes(val.id || ''),
        }))
      ) as IStaff[]
    } catch (error) {
      openMessage(this.$t(`errors.${error}`), 'error')
      this.$emit('update:onClose')
    } finally {
      this.loading = false
    }
  }
}
