<template>
  <div class="el-form-item mb-0" :class="{ 'is-error': !!error || errorBorder }">
    <label :for="fieldName" v-if="label">
      {{ label }}
    </label>
    <div class="el-form-item__content">
      <component
        v-if="component"
        v-model="value"
        v-bind="filteredProps"
        :is="component"
        :name="fieldName"
        :placeholder="placeholder"
        @change="$emit('change')"
        @blur="blur"
        @input="input"
      />
      <el-input
        v-else-if="mask"
        v-model="value"
        v-maska="mask"
        :name="fieldName"
        :type="inputType"
        :disabled="disabled"
        :placeholder="placeholder"
        @blur="blur"
        @input="input"
        @change="change"
      >
      </el-input>
      <el-input
        :autofocus="true"
        v-else
        v-model="value"
        :name="fieldName"
        :type="inputType"
        :disabled="disabled"
        :placeholder="placeholder"
        :minlength="minLength"
        :maxlength="maxLength"
        :resize="'horizontal'"
        :min="minValue"
        :max="maxValue"
        @blur="blur"
        @input="input"
        @change="change"
        :show-word-limit="isShowLimit"
      >
      </el-input>
      <span v-show="!disableError && error" class="el-form-item__error">{{ error }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  directives: { maska },
  inheritAttrs: false,
  emits: ['update:modelValue', 'change', 'blur', 'input'],
  name: 'BaseInput',
})
export default class BaseInput extends Vue {
  @Prop({ type: Function }) readonly component?: Function
  @Prop({ type: String }) readonly fieldName?: string = ''
  @Prop({ type: [String, Number, Array, Object, Date] })
  readonly modelValue?: unknown

  @Prop({ type: String }) readonly label?: string
  @Prop({ type: String }) readonly placeholder?: string = ''
  @Prop({ type: Boolean }) readonly disabled?: boolean = false
  @Prop({ type: String }) readonly inputType?: string = 'text'
  @Prop({ type: Boolean }) readonly isShowLimit?: boolean = false
  @Prop({ type: String }) readonly mask?: string
  @Prop({ type: Number }) readonly minLength?: number
  @Prop({ type: Number }) readonly maxLength?: number
  @Prop({ type: Object }) readonly selections?: object
  @Prop({ type: Boolean }) readonly disableError?: boolean
  @Prop({ type: String }) readonly barcodeType?: string
  @Prop({ type: String }) readonly error?: string
  @Prop({ type: Boolean }) readonly errorBorder?: boolean = false
  // input props number
  @Prop({ type: Number }) readonly minValue?: number
  @Prop({ type: Number }) readonly maxValue?: number
  @Prop({ type: Number }) readonly precision?: number

  get filteredProps() {
    const fProps = { ...this.$props }
    delete fProps.component
    return fProps
  }

  get value() {
    return this.modelValue
  }

  set value(newVal) {
    this.$emit('update:modelValue', newVal)
  }

  @Emit('blur')
  blur() {
    return 'blur'
  }

  @Emit('input')
  input(value: string) {
    return value
  }

  @Emit('change')
  change() {
    return 'change'
  }
}
</script>
