
import { IProjectCustomField, ICountryCode } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'
import { POPULAR_COUNTRY_LIST } from '@/utils/constants'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'

@Options({
  components: { TextboxField },
})
export default class PhoneNumber extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField

  getCountryName(country: ICountryCode) {
    return `${this.$t(`countryList.${country.code}`)}${country.dialCode ? ` (${country.dialCode})` : ''}`
  }

  get countryList() {
    return {
      popularCountries: POPULAR_COUNTRY_LIST,
      allCountries: OTHER_COUNTRY_LIST,
    } as {
      popularCountries: Record<string, ICountryCode>
      allCountries: Record<string, ICountryCode>
    }
  }
}
