<template>
  <div class="text-right mb-5">
    <el-select
      v-model="selectedUserGroupIndex"
      :disabled="isReadOnlyMode"
      :placeholder="$t('selectOne')"
      class="mr-3"
      filterable
    >
      <el-option :value="-1" :label="$t('selectOne')">
        {{ $t('selectOne') }}
      </el-option>
      <el-option v-for="(value, key) in displayUserGroups" :key="key" :label="value.name" :value="key">
        {{ value.name }}
      </el-option>
    </el-select>
    <el-button
      :disabled="displayUserGroups?.length < 1 || selectedUserGroupIndex === -1"
      type="primary"
      icon="el-icon-plus"
      size="medium"
      class="w-20"
      @click="onAddNewGroup"
      >{{ $t('add') }}</el-button
    >
    <el-button :disabled="isReadOnlyMode" type="primary" icon="el-icon-plus" @click="showDialog()">
      {{ $t('permissions.create') }}
    </el-button>
  </div>
  <div class="permission-tab overflow-auto">
    <PermissionTable
      v-if="!!projectDetail && !!permissionGroup"
      :trackPoints="trackPoints"
      :trackPointsHeaderName="trackPointsHeaderName"
      :projectId="newProjectId"
      v-model:permissionGroup="permissionGroup"
      @showDialog:changeName="showDialog"
      @update:tablePermission="onTableGroupUpdate"
      @update:onGroupClicked="onGroupClicked"
    />
  </div>
  <el-dialog
    :custom-class="'el-dialog--custom'"
    v-model="isShowDialog"
    top="0"
    :destroy-on-close="false"
    :close-on-click-modal="false"
    :show-close="true"
    @close="onCloseDialog"
  >
    <div class="flex items-center mb-5">
      <div class="text-right w-1/3 mr-4">{{ $t('permissions.group_name') }}</div>
      <div class="w-2/3">
        <BaseInput
          v-bind="userGroupDialog.name"
          :error="userGroupDialog.errorMessage"
          :placeholder="$t('permissions.group_name')"
          v-model="userGroupDialog.name"
          @input="clearError()"
          @change="clearError()"
          @blur="validate()"
        />
      </div>
    </div>
    <template #footer>
      <div class="flex">
        <div class="flex-1">
          <el-button type="default" class="btn-default-cancel" @click="isShowDialog = false">
            {{ $t('cancel') }}
          </el-button>
        </div>
        <div class="flex-1">
          <el-button type="primary" @click="onCreateNewGroup()">{{ $t('ok') }}</el-button>
        </div>
      </div>
    </template>
  </el-dialog>

  <el-dialog
    :custom-class="'el-dialog--custom'"
    v-model="isShowSettingDialog"
    top="0"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :show-close="true"
    @close="onCloseSettingDialog"
  >
    <template #title>{{ `${$t('permissions.group_name')} ｜ ${currentGroupSettingId.name} ` }}</template>
    <div>
      <PermissionDialogSetting
        @update:onClose="onCloseSettingDialog()"
        @update:onSave="onPermissionDialogSave"
        :group="currentGroupSettingId"
        :staffs="staffs"
      />
    </div>
  </el-dialog>
</template>

<script lang="ts">
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PermissionDialogSetting from '@/components/common/PermissionDialogSetting.vue'
import PermissionTable from '@/components/common/PermissionTable.vue'
import Dialog from '@/components/customFields/Dialog.vue'
import IconNotFound from '@/components/svg/IconNotFound.vue'
import { PROJECT_NEW_KEY_ID } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  ICreateUserGroup,
  ICreateUserGroupForm,
  IPermission,
  IProjectDetail,
  IStaff,
  ITrackingPermissions,
  ITrackpoint,
  ITrackPointKeyVal,
  ITrackPointPermissions,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  components: { IconNotFound, BaseInput, Dialog, ItemNotFound, PermissionTable, PermissionDialogSetting },
  directives: { maska },
  emits: ['update:permission'],
  name: 'Permission',
})
export default class Permission extends Vue {
  arrTrackPoints: ITrackPointKeyVal[] = []
  permissionGroup: ICreateUserGroupForm[] = []
  trackPoints: ITrackpoint[] = []
  trackPointsHeaderName: string[] = []
  isShowDialog = false
  isShowSettingDialog = false
  userGroupDialog = { name: '', errorMessage: '' }
  indexChangeUserGroupName = -1
  groupPermissionRequest: ICreateUserGroupForm[] = []
  currentSelectedUserGroupIndex = -1

  displayUserGroups = [] as ICreateUserGroup[]
  selectedUserGroupIndex = -1

  get currentGroupSettingId(): ICreateUserGroupForm {
    return this.permissionGroup?.[this.currentSelectedUserGroupIndex] ?? {}
  }

  onCloseDialog() {
    this.clearError()
    this.userGroupDialog.name = ''
    this.isShowDialog = false
  }

  onCloseSettingDialog() {
    this.clearError()
    this.isShowSettingDialog = false
    this.currentSelectedUserGroupIndex = -1
  }

  onPermissionDialogSave(savedUserGroup: ICreateUserGroupForm) {
    this.permissionGroup[this.currentSelectedUserGroupIndex] = savedUserGroup
    this.onCloseSettingDialog()
  }

  onGroupClicked(index: number) {
    this.currentSelectedUserGroupIndex = index
    this.isShowSettingDialog = true
  }

  clearError() {
    this.userGroupDialog.errorMessage = ''
  }

  validate() {
    if (this.userGroupDialog.name === '') {
      this.userGroupDialog.errorMessage = this.$t('mandatory_field')
      return false
    }
    return true
  }

  onTableGroupUpdate(userGroup: ICreateUserGroup[]) {
    this.groupPermissionRequest = userGroup
    this.$emit('update:permission', this.groupPermissionRequest)
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get projectDetail(): IProjectDetail {
    return this.$store.state?.project?.projectDetail
  }

  get projectId(): string {
    return (
      (this.projectDetail.mainInfo.isDraft
        ? this.projectDetail.mainInfo.targetProjectId
        : this.projectDetail.mainInfo.id) || ''
    )
  }

  get newProjectId(): string {
    return PROJECT_NEW_KEY_ID
  }

  get permissionTrackPoints() {
    const trackPoints = Object.entries(this.projectDetail.trackPoints || {}).map((val) => {
      return [
        val[0],
        {
          canEdit: true,
          canView: true,
          canDelete: true,
        } as ITrackpoint,
      ]
    })
    return Object.fromEntries(trackPoints)
  }

  async created() {
    this.initTrackPointData()
    this.initPermissionGroup()
  }

  @Watch('$store.state.project.projectDetail.trackPoints')
  initTrackPointData() {
    const projectDetail = cloneDeep(this.$store.state.project.projectDetail) as IProjectDetail
    const trackPointsHeaderName: string[] = []

    const trackPoints = Object.entries(projectDetail.trackPoints || {}).map((val) => {
      trackPointsHeaderName.push(val[1].name || '')
      return [
        val[0],
        {
          canEdit: true,
          canView: true,
          canDelete: true,
        } as ITrackpoint,
      ]
    })

    this.trackPoints = Object.fromEntries(trackPoints)
    this.trackPointsHeaderName = trackPointsHeaderName
  }

  @Watch('$store.state.project.projectDetail.permissionGroup')
  @Watch('$store.state.project.userGroups')
  @Watch('$store.state.project.staffs')
  async initPermissionGroup() {
    try {
      if (isEmpty(this.permissionGroup)) {
        this.permissionGroup = []
        let userGroups = []
        let staffs = [] as IStaff[]
        if (typeof this.$store.state?.project?.projectDetail.permissionGroup !== 'undefined') {
          this.permissionGroup = cloneDeep(this.$store.state?.project?.projectDetail.permissionGroup) || []
        }
        if (typeof this.$store.state?.project?.userGroups !== 'undefined') {
          userGroups = cloneDeep(this.$store.state?.project?.userGroups) || []
        }
        if (typeof this.$store.state?.project?.staffs !== 'undefined') {
          staffs = cloneDeep(this.$store.state?.project?.staffs) || []
        }

        const permissionGroupIds = this.permissionGroup.map((item) => item.id)

        const resultUserGroups = [] as ICreateUserGroup[]
        userGroups.forEach((value: ICreateUserGroup) => {
          if (value.isActive && !permissionGroupIds.includes(value.id)) {
            value.userIds = [] as string[]
            staffs.forEach((staff: IStaff) => {
              if (staff.userGroupIds?.includes(value.id)) {
                value.userIds.push(staff.id || '')
              }
            })
            resultUserGroups.push(value)
          }
        })

        this.displayUserGroups = resultUserGroups
      }
    } catch (error) {
      errorHandler(error)
    }
  }

  get staffs() {
    return this.$store.state?.project?.staffs || []
  }

  @Watch('$store.state.project.projectDetail.trackPoints', { deep: true })
  checkAddTrackPoint() {
    const trackPoints = cloneDeep(this.$store.state?.project?.projectDetail.trackPoints) as Record<string, ITrackpoint>
    this.permissionGroup.map((item) => {
      let trackingPermissions =
        item.projectPermissions?.[this.newProjectId]?.trackingPermissions ||
        ({} as Record<string, ITrackPointPermissions>)
      if (isEmpty(trackingPermissions)) {
        const firstKey = Object.keys(item.projectPermissions || {})[0] ?? ''
        if (firstKey) {
          trackingPermissions =
            item.projectPermissions?.[firstKey]?.trackingPermissions || ({} as Record<string, ITrackPointPermissions>)
        }
      }
      if (trackingPermissions) {
        for (const key in trackPoints) {
          if (isEmpty(trackingPermissions[key])) {
            trackingPermissions[key] = { canEdit: true, canView: true, canDelete: true }
          }
        }
      }
    })
  }

  showDialog(index = -1) {
    if (index !== -1) {
      const userGroup = this.permissionGroup?.[index]
      this.userGroupDialog.name = userGroup?.name || ''
      this.indexChangeUserGroupName = index
    }

    if (Object.keys(this.permissionTrackPoints || {}).length < 1) {
      openMessage(this.$t('permissions.no_trackingPoints'), 'error')
      return
    }
    this.isShowDialog = true
  }

  onCreateNewGroup() {
    if (!this.validate()) return
    this.isShowDialog = false
    if (this.indexChangeUserGroupName !== -1) {
      this.permissionGroup[this.indexChangeUserGroupName].name = this.userGroupDialog.name
      this.indexChangeUserGroupName = -1
    } else {
      const newGroup: ICreateUserGroupForm = JSON.parse(
        JSON.stringify({
          name: this.userGroupDialog.name,
          newGroupId: '',
          errorMessage: '',
          isNewGroup: true,
          isMandatory: true,
          projectPermissions: {
            [this.newProjectId]: {
              trackingPermissions: { ...this.permissionTrackPoints },
              activationDataPermission: { canView: true, canEdit: true },
              generateBarcodePermission: { canEdit: true },
            },
          },
        })
      )
      this.permissionGroup.push(newGroup)
    }
  }

  onAddNewGroup() {
    const selectedUserGroup = cloneDeep(this.displayUserGroups[this.selectedUserGroupIndex]) as ICreateUserGroupForm
    const projectPermissions = selectedUserGroup.projectPermissions as ITrackingPermissions
    projectPermissions[this.newProjectId] = cloneDeep({
      trackingPermissions: { ...this.trackPoints },
      activationDataPermission: { canView: true, canEdit: true },
      generateBarcodePermission: { canEdit: true },
    }) as IPermission
    this.permissionGroup.push(selectedUserGroup)

    this.displayUserGroups.splice(this.selectedUserGroupIndex, 1)
    this.selectedUserGroupIndex = -1
  }

  onAddNewNode() {
    this.initTrackPointData()
    this.initPermissionGroup()
  }

  reindexPermissionGroup(node: string) {
    this.permissionGroup.map((item: ICreateUserGroupForm) => {
      if (item.projectPermissions) {
        const projectPermissionGroup = item.projectPermissions[this.newProjectId]
        for (const index in projectPermissionGroup.trackingPermissions) {
          if (index === node) {
            delete projectPermissionGroup.trackingPermissions[index]
          } else if (Number(index) > Number(node)) {
            const existItem = projectPermissionGroup.trackingPermissions[index]
            delete projectPermissionGroup.trackingPermissions[index]
            const newkey = String(Number(index) - 1)
            projectPermissionGroup.trackingPermissions[newkey] = existItem
          }
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.permission-tab {
  max-height: calc(100vh - 180px - 60px);
}
</style>
