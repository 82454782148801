<template>
  <!-- name -->
  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('Name') }}
    </div>
    <div class="col-span-9">
      <TextboxField :type="'text'" :name="'label'" v-model="customField.label" :isRequired="true" />
    </div>
  </div>

  <!-- Add button -->
  <div class="flex items-center gap-4 my-4">
    <div class="flex-grow">
      <label>{{ $t('customFields.listButtons') }}</label>
    </div>
    <div>
      <el-button type="primary" icon="el-icon-plus" @click="addOption">
        {{ $t('add') }}
      </el-button>
    </div>
  </div>

  <!-- Attributes -->
  <div class="flex items-center gap-4 mb-5" :key="key" v-for="(item, key) in getOptions">
    <div class="flex-1">
      <TextboxField
        :type="'text'"
        :name="`selections.${key}.label`"
        :placeholder="$t('Name')"
        v-model="item.label"
        :isRequired="true"
      />
    </div>
    <div>
      <el-radio
        @change="onChangeValidate(key)"
        @keyup.space="onChangeValidate(key)"
        v-model="item.default"
        :label="true"
      >
        {{ $t('customFields.default') }}
      </el-radio>
    </div>
    <div>
      <el-button
        type="default"
        class="bg-red-min text-white focus:text-white"
        size="medium"
        @click="deleteOption(key)"
        :disabled="isDisabledDelete"
      >
        {{ $t('delete') }}
      </el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { IProjectCustomField, TOptions } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { generateNewKey } from '@/utils/helpers'
import TextboxField from '@/components/validateFields/TextboxField.vue'

@Options({
  components: { TextboxField },
  name: 'Radio',
})
export default class Radio extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField
  prefixKey = 'r'

  get getOptions(): TOptions {
    if (!this.customField.selections) {
      const newKey = generateNewKey({}, this.prefixKey)
      this.customField.selections = {
        [newKey]: { label: '', default: true },
      } as TOptions
    }

    return this.customField.selections
  }

  get isDisabledDelete() {
    return !(this.customField.selections && Object.keys(this.customField.selections).length > 1)
  }

  onChangeValidate(keyChange: string) {
    for (const key in this.customField.selections) {
      if (keyChange === key) {
        this.customField.selections[key].default = true
      } else {
        this.customField.selections[key].default = false
      }
    }
  }

  deleteOption(key: string) {
    if (this.customField.selections) {
      delete this.getOptions[key]
      Object.values(this.getOptions)[0].default = true
    }
  }

  addOption() {
    if (!this.customField.selections) {
      this.customField.selections = {} as TOptions
    }
    const newKey = generateNewKey(this.customField.selections, this.prefixKey)
    this.customField.selections[newKey] = { label: '', default: false }
  }
}
</script>
