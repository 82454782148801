
import TextboxField from '@/components/validateFields/TextboxField.vue'
import isEmpty from 'lodash/isEmpty'
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop } from 'vue-property-decorator'
import RichTextEditor from '../project/RichTextEditor.vue'

@Options({
  components: { TextboxField, RichTextEditor },
  name: 'Calculation',
})
export default class Calculation extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField
  @InjectReactive() customFields!: []

  get isEmptyCustomFields() {
    return isEmpty(this.customFields)
  }
}
