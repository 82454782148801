<template>
  <div class="grid grid-cols-12 items-center gap-3 mb-5 gap-y-4">
    <div class="text-right col-span-3">
      {{ $t('Name') }}
    </div>
    <div class="col-span-9">
      <TextboxField :type="'text'" :name="'label'" v-model="customField.label" :isRequired="true" />
    </div>
    <div class="text-right col-span-3">
      {{ $t('template') }}
    </div>
    <div class="col-span-9">
      <TextboxField
        :max="customField.maxLength"
        :type="'text'"
        :name="'template'"
        v-model="customField.template"
        :isRequired="true"
      />
    </div>
  </div>
  <div class="grid grid-cols-12 items-center gap-3 mb-4">
    <div class="text-right col-span-3">
      {{ $t('customFields.characterLimit') }}
    </div>
    <div class="flex items-center col-span-9">
      <TextboxField :type="'text'" :name="'minLength'" v-model="customField.minLength" :isNumber="true" />
      <span class="mx-4">〜</span>
      <TextboxField :type="'text'" :name="'maxLength'" v-model="customField.maxLength" :isNumber="true" />
    </div>
  </div>
</template>

<script lang="ts">
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'

@Options({
  components: { TextboxField },
  name: 'Reference',
})
export default class Reference extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField
}
</script>
