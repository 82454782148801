<template>
  <div class="grid grid-cols-12 items-center gap-3 mb-5">
    <div class="text-right col-span-3">
      {{ $t('Name') }}
    </div>
    <div class="col-span-9">
      <TextboxField :type="'text'" :name="'label'" v-model="customField.label" :isRequired="true" />
    </div>
  </div>
  <div class="ghg-calculation-description-area">
    <p v-for="key in calculationTypeKeys" :key="key" class="leading-normal">
      ● {{ $t(`ghgEmission.description.${key}`) }}
    </p>
  </div>
  <div class="ghg-calculation-type-select-area">
    <div class="label">
      {{ $t(`ghgEmission.label`) }}
    </div>
    <div class="options">
      <el-radio-group v-model="customField.calculationType">
        <el-radio v-for="key in calculationTypeKeys" :key="key" :label="key" :value="key">
          {{ $t(`ghgEmission.${key}`) }}
        </el-radio>
      </el-radio-group>
    </div>
  </div>
  <div class="notes my-3 leading-normal">
    <p>
      {{ $t('ghgEmission.notes[0]') }}
    </p>
    <p>
      {{ $t('ghgEmission.notes[1]') }}
    </p>
  </div>
</template>

<script lang="ts">
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'
import { GhgEmissionCalculationType } from 'smartbarcode-web-core/src/utils/ghgEmission/GhgEmissionCalculationType'

@Options({
  components: { TextboxField },
  name: 'Textbox',
})
export default class GhgEmission extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField

  data() {
    return {
      calculationTypeKeys: ['tonkilo', 'modifiedTonkilo', 'fuel', 'fuelEfficiency'] as GhgEmissionCalculationType[],
    }
  }

  mounted() {
    this.customField.calculationType = this.customField.calculationType
      ? this.customField.calculationType
      : this.calculationTypeKeys[0]
  }
}
</script>

<style scoped>
.ghg-calculation-description-area {
  border-top: 1px solid #bdbdbd;
  padding: 15px;
}

.ghg-calculation-type-select-area {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
}

.ghg-calculation-type-select-area .label {
  flex-basis: 100%;
}

.notes {
  font-style: italic;
}
</style>
